import React from 'react';
import "../src/assests/sass/Style.scss";
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Login from './component/Form/Login/Login';
import Dashboard from './component/Dashboard/Dashboard';
import Test from './component/Test/Test';
import store from './redux/store';
import { Provider } from 'react-redux';
import Categories from './component/Categories/Categories';
import Packages from './component/Packages/Packages';
import Patient from './component/Patient/Patient';
import Doctor from './component/Doctor/Doctor';
import Hospital from './component/Hospital/Hospital';
import Booking from './component/Booking/Booking';
import Commission from './component/Commission/Commission';
import NewBooking from './component/Booking/NewBooking';
import PaginationComponent from './component/Pagination/PaginationComponent';
import BookingDetail from './component/Booking/BookingDetail';
import Sidebar from './component/Sidebar.js/Sidebar';
import Feedback from './component/Feedback/Feedback';
import ContactUs from './component/ContactUs/ContactUs';
import Print from './component/Print/Print';


function App() {

  function CommonSidebar() {
    return (
      <>
        <Sidebar />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />

            <Route element={<CommonSidebar />}>
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              <Route path="/categories" element={<Categories />} />
              <Route path="/test" element={<Test />} />
              <Route path="/packages" element={<Packages />} />
              <Route path="/patient" element={<Patient />} />
              <Route path="/doctor" element={<Doctor />} />
              <Route path="/hospital" element={<Hospital />} />
              <Route path="/booking" element={<Booking />} />
              <Route path="/new_booking" element={<NewBooking />} />
              <Route path="/commission" element={<Commission />} />
              <Route path="/pagination" element={<PaginationComponent />} />
              <Route path="/booking_detail" element={<BookingDetail />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/print" element={<Print />} />
            </Route>
          </Routes>
        </Router>
      </Provider>,
    </>
  );
}

export default App;
