import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar.js/Sidebar";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPatientList } from "../../redux/getPatientListSlice";
import { getDoctorList } from "../../redux/getDoctorListSlice";
import { getPackageList } from "../../redux/getPackageListSlice";
import { getTestList } from "../../redux/getTestListSlice";
import { getHospitalList } from "../../redux/getHospitalListSlice";
import {
  clearPostBookingRecordData,
  postBookingRecord,
} from "../../redux/postBookingRecordSlice";
import { addPatient, clearAddPatientData } from "../../redux/addPatientSlice";
import {
  addHospital,
  clearAddHospitalData,
} from "../../redux/addHospitalSlice";
import { addDoctor, clearAddDoctorData } from "../../redux/addDoctorSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { updateTest } from "../../redux/updateTestSlice";
import Modal from 'react-modal';
import { addTest } from "../../redux/addTestSlice";
import { getCategoryList } from "../../redux/getCategoryListSlice";
import cross from '../../assests/img/cross.png';

const modal_setting = {
  content: {
    top: "20%",
    left: "50%",
    right: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, 0)",
    maxWidth: "100%",
    width: "420px",
    borderRadius: "8px",
  },
};


const NewBooking = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  //add patient Selector
  const addPatientSuccess = useSelector(
    (state) => state.addPatientReducer.data
  );

  //Add Doctor Selector
  const addDoctorSuccess = useSelector((state) => state.addDoctorReducer.data);

  //Add Hospital Selector
  const addHospitalSuccess = useSelector(
    (state) => state.addHospitalReducer.data
  );

  //add booking selector
  const bookingRecordSuccess = useSelector(
    (state) => state.postBookingRecordSReducer.data
  );

  //add Test Success
  const addTestSuccess = useSelector(
    (state) => state.addTestReducer.data
  );

  //Patient Fields
  const [patients, setPatients] = useState([]);
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [patientMobile, setPatientMobile] = useState("");
  const [patientAddress, setPatientAddress] = useState("");
  const [patientGender, setPatientGender] = useState(0);
  const [tests, setTests] = useState("");
  const [packages, setPackage] = useState("");
  const [hospital, setHospital] = useState("");

  const [amount, setAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [payment, setPayment] = useState(1);
  const [reffered, setReffered] = useState(1);
  const [inputCashOnline, setInputCashOnline] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [onlineAmount, setOnlineAmount] = useState(0);
  const [paymentMode, setPaymentMode] = useState(1);

  //Doctor Fields
  const [doctors, setDoctors] = useState([]);
  const [doctorFirstName, setDoctorFirstName] = useState("");
  const [doctorLastName, setDoctorLastName] = useState("");
  const [doctortEmail, setDoctorEmail] = useState("");
  const [doctorMobileNumber, setDoctorMobileNumber] = useState("");
  const [doctorAddress, setDoctorAddress] = useState("");

  //Hospital Fields
  const [hospitalName, setHospitalName] = useState("");
  const [hospitalEmail, setHospitalEmail] = useState("");
  const [hospitalMobileNumber, setHospitalMobileNumber] = useState("");
  const [hospitalAddress, setHospitalAddress] = useState("");

  const getPatientListResponse = useSelector(
    (state) => state.getPatientListReducer.data
  );
  // const isLoading = useSelector((state) => state.getPatientListReducer.isLoading);
  const getDoctorListResponse = useSelector(
    (state) => state.getDoctorListReducer.data
  );
  const getTestListResponse = useSelector(
    (state) => state.getTestListReducer.data
  );
  const getPackageListResponse = useSelector(
    (state) => state.getPackageListReducer.data
  );
  const getHospitalListResponse = useSelector(
    (state) => state.getHospitalListReducer.data
  );

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionName, setSelectedOptionName] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [newTest, setNewTest] = useState(false);

  const [selectedOptionPackage, setSelectedOptionPackage] = useState("");
  const [selectedOptionNamePackage, setSelectedOptionNamePackage] =
    useState("");
  const [isDropdownOpenPackage, setIsDropdownOpenPackage] = useState(false);

  const [selectedOptionDoctor, setSelectedOptionDoctor] = useState("");
  const [selectedOptionNameDoctor, setSelectedOptionNameDoctor] = useState("");
  const [isDropdownOpenDoctor, setIsDropdownOpenDoctor] = useState(false);

  const [selectedOptionHospital, setSelectedOptionHospital] = useState("");
  const [selectedOptionNameHospital, setSelectedOptionNameHospital] =
    useState("");
  const [isDropdownOpenHospital, setIsDropdownOpenHospital] = useState(false);

  useEffect(() => {
    if (selectedOptionName === "") {
      setSelectedOption("");
      setPatientFirstName("");
      setPatientLastName("");
      setPatientAddress("");
      setPatientEmail("");
      setPatientMobile("");
    }
  }, [selectedOptionName]);

  const handleInputChange = (event) => {
    setSelectedOptionName(event.target.value);
    setIsDropdownOpen(true);
  };

  const handleInputChangePackage = (event) => {
    setSelectedOptionNamePackage(event.target.value);
    setIsDropdownOpenPackage(true);
  };

  const handleInputChangeHospital = (event) => {
    setSelectedOptionNameHospital(event.target.value);
    setIsDropdownOpenHospital(true);
  };

  const handleSelectChange = (item) => {
    setSelectedOption(item._id);
    setSelectedOptionName(item.firstName + " " + item.lastName);
    setPatientFirstName(item.firstName);
    setPatientLastName(item.lastName);
    setPatientAddress(item.address);
    setPatientEmail(item.email);
    setPatientMobile(item.mobileNumber);
    setPatientGender(item.gender);
    setIsDropdownOpen(false);
  };

  const filteredOptions = getPatientListResponse?.data.filter((item) =>
    (item.firstName + item.lastName)
      .toLowerCase()
      .includes(selectedOptionName.toLowerCase())
  );

  const filteredOptionsDoctor = getDoctorListResponse?.data.filter((item) =>
    (item.firstName + item.lastName)
      .toLowerCase()
      .includes(selectedOptionNameDoctor.toLowerCase())
  );

  const filteredOptionsPackage = getPackageListResponse?.data.filter((item) =>
    item.name.toLowerCase().includes(selectedOptionNamePackage.toLowerCase())
  );

  const filteredOptionsHospital = getHospitalListResponse?.data.filter((item) =>
    item.name.toLowerCase().includes(selectedOptionNameHospital.toLowerCase())
  );

  const handleInputChangeDoctor = (event) => {
    // setSelectedOption(event.target.value);
    setSelectedOptionNameDoctor(event.target.value);
    setIsDropdownOpenDoctor(true);
  };

  const handleSelectChangeDoctor = (item) => {
    setSelectedOptionDoctor(item._id);
    setSelectedOptionNameDoctor(item.firstName + " " + item.lastName);
    setDoctorFirstName(item.firstName);
    setDoctorLastName(item.lastName);
    setDoctorAddress(item.address);
    setDoctorEmail(item.email);
    setDoctorMobileNumber(item.mobileNumber);
    setIsDropdownOpenDoctor(false);
    setDueAmount(item.dueAmount);
    setOnlineAmount(item.onlineAmount);
    setCashAmount(item.cashAmount);
    // setPaymentMode(item.paymentMode);
  };

  const handleSelectChangeHospital = (item) => {
    setSelectedOptionHospital(item._id);
    setSelectedOptionNameHospital(item.name);
    setHospitalName(item.name);
    setHospitalEmail(item.email);
    setHospitalMobileNumber(item.mobileNumber);
    setHospitalAddress(item.address);
    setIsDropdownOpenHospital(false);
    setDueAmount(item.dueAmount);
    setOnlineAmount(item.onlineAmount);
    setCashAmount(item.cashAmount);
    // setPaymentMode(item.paymentMode);
  };

  const handleSelectChangePackage = (item) => {
    setSelectedOptionPackage(item._id);
    setSelectedOptionsTest([]);
    setSelectedOptionNamePackage(item.name);
    setIsDropdownOpenPackage(false);
    setAmount(item.amount);
    if (paymentMode == 1) {
      setCashAmount(item.amount)
      setOnlineAmount(0)
    }
    else if (paymentMode == 2) {
      setOnlineAmount(item.amount)
      setCashAmount(0)
    }

    // setDiscount(item.discount);
    // setPaymentMode(item.paymentMode);
  };

  useEffect(() => {
    const paylaod = {
      skip: -1,
    };
    dispatch(getPatientList(paylaod));
    dispatch(getDoctorList(paylaod));
    dispatch(getPackageList(paylaod));
    dispatch(getTestList(paylaod));
    dispatch(getHospitalList(paylaod));
  }, []);

  useEffect(() => {
    const paylaod = {
      skip: 0,
    }
    dispatch(getCategoryList(paylaod));
  }, [])

  useEffect(() => {
    console.log("getPatientListResponse ===> ", getPatientListResponse);
    if (getPatientListResponse != null && getPatientListResponse.status == 1) {
      setPatients(getPatientListResponse.data);
    }
  }, [getPatientListResponse]);

  useEffect(() => {
    console.log("getDoctorListResponse ===> ", getDoctorListResponse);
    if (getDoctorListResponse != null && getDoctorListResponse.status == 1) {
      setDoctors(getDoctorListResponse.data);
    }
  }, [getDoctorListResponse]);

  const [selectedOptionsTest, setSelectedOptionsTest] = useState([]);
  const [selectedOptionsTestStr, setSelectedOptionsTestStr] = useState("");
  const [isDropdownOpenTest, setIsDropdownOpenTest] = useState(false);
  const [inputValueTest, setInputValueTest] = useState("");

  const selectedOptionsNamesTest = selectedOptionsTest
    .map((option) => option.name)
    .join(", ");

  const handleInputChangeTest = (e) => {
    const txtValue = e.target.value.split(",")
    setInputValueTest(txtValue[txtValue.length - 1]);
    setSelectedOptionsTestStr(e.target.value)
    setIsDropdownOpenTest(true);
  };


  const filteredOptionsTest = getTestListResponse?.data.filter((item) =>
    item.name.toLowerCase().includes(inputValueTest.toLowerCase())
  );

  const handleSelectChangeTest = (item) => {
    setSelectedOptionPackage("");
    setSelectedOptionNamePackage("");
    setInputValueTest("");
    setIsDropdownOpenTest(false)

    const isSelected = selectedOptionsTest.some(
      (option) => option._id === item._id
    );
    // if (isSelected) {
    //   setSelectedOptionsTest(
    //     selectedOptionsTest.filter((option) => option._id !== item._id)
    //   );
    // } else {
    setSelectedOptionsTest([...selectedOptionsTest, item]);

    let str = item.name.concat(",")
    // console.log(str, "result =====> ", result)
    if (selectedOptionsTestStr.includes(str)) {
      let finalStr = selectedOptionsTestStr.replace(str, "");
      setSelectedOptionsTestStr(finalStr)
    } else {
      const result = selectedOptionsTestStr.substring(0, selectedOptionsTestStr.lastIndexOf(","));
      const value = result == "" ? result.concat(item.name).concat(",") : result.concat(",").concat(item.name).concat(",")
      setSelectedOptionsTestStr(value)
    }

    // }
  };

  useEffect(() => {
    if (selectedOptionsTest.length > 0) {
      var am = 0;
      selectedOptionsTest.forEach((element) => {
        am += element.amount;
        setAmount(am);
        if (paymentMode == 1) {
          setCashAmount(am)
          setOnlineAmount(0)
        }
        else if (paymentMode == 2) {
          setOnlineAmount(am)
          setCashAmount(0)
        }
      });
    } else {
      if (selectedOptionNamePackage == "") setAmount(0);
    }
  }, [selectedOptionsTest]);

  useEffect(() => {
    console.log("getTestListResponse ==>", getTestListResponse);
    if (getTestListResponse != null && getTestListResponse.status == 1) {
      setTests(getTestListResponse.data);
      if (newTest) {
        handleSelectChangeTest(getTestListResponse.data[0])
      }
    }
  }, [getTestListResponse]);

  useEffect(() => {
    console.log("getPackageListResponse ==>", getPackageListResponse);
    if (getPackageListResponse != null && getPackageListResponse.status == 1) {
      setPackage(getPackageListResponse.data);
    }
  }, [getPackageListResponse]);

  useEffect(() => {
    console.log("getHospitalListResponse ===>", getHospitalListResponse);
    if (
      getHospitalListResponse != null &&
      getHospitalListResponse.status == 1
    ) {
      setHospital(getHospitalListResponse.data);
    }
  }, [getHospitalListResponse]);

  const onSubmitClick = () => {
    if (discount > amount) {
      alert("Discount amount must be less than amount");
    } else if (selectedOption == "") {
      if (patientFirstName === "") {
        alert("Please Enter Patient Name");
      } else if (patientMobile === "") {
        alert("Please Enter Patient Mobile Number!");
      } else if (patientGender == 0) {
        alert("Please Select Patient Gender!");
      } else {
        const payload = {
          firstName: patientFirstName,
          lastName: patientLastName,
          email: patientEmail,
          mobileNumber: patientMobile,
          address: patientMobile,
          gender: patientGender,
        };
        dispatch(addPatient(payload));
      }
    } else if (selectedOptionsTest.length == 0 && selectedOptionPackage == "") {
      alert("Please select any test or package!");
    } else if (reffered == 1) {
      const payload = {
        userId: selectedOption,
        testIds: selectedOptionsTest,
        packageId: selectedOptionPackage,
        doctorId: selectedOptionDoctor,
        hospitalId: selectedOptionHospital,
        amount: amount,
        discountAmount: discount,
        dueAmount: dueAmount,
        cashAmount: cashAmount,
        onlineAmount: onlineAmount,
        paymentMode: paymentMode,
        bookingDate: moment(new Date()).format("YYYY-MM-DD"),
        bookingTime: moment(new Date()).format("HH:mm"),
      };
      console.log("Booking Payload 1===> ", payload);
      dispatch(postBookingRecord(payload));
    } else if (reffered == 2) {
      if (selectedOptionDoctor == "") {
        if (doctorFirstName === "") {
          alert("Please Enter Patient Name");
        } else if (doctorMobileNumber === "") {
          alert("Please Enter Patient Mobile Number!");
        } else {
          const payload = {
            firstName: doctorFirstName,
            lastName: doctorLastName,
            email: doctortEmail,
            mobileNumber: doctorMobileNumber,
          };
          dispatch(addDoctor(payload));
        }
      } else {
        const payload = {
          userId: selectedOption,
          testIds: selectedOptionsTest,
          packageId: selectedOptionPackage,
          doctorId: selectedOptionDoctor,
          hospitalId: selectedOptionHospital,
          amount: amount,
          discountAmount: discount,
          dueAmount: dueAmount,
          cashAmount: cashAmount,
          onlineAmount: onlineAmount,
          paymentMode: paymentMode,
          bookingDate: moment(new Date()).format("YYYY-MM-DD"),
          bookingTime: moment(new Date()).format("HH:mm"),
        };
        console.log("Booking Payload 2===> ", payload);
        dispatch(postBookingRecord(payload));
      }
    } else if (reffered == 3) {
      if (selectedOptionHospital == "") {
        if (hospitalName === "") {
          alert("Please Enter Corporate Name");
        } else if (hospitalMobileNumber === "") {
          alert("Please Enter Corporate Mobile Number!");
        } else if (hospitalAddress === "") {
          alert("Please Enter Corporate Address!");
        } else {
          const payload = {
            name: hospitalName,
            email: hospitalEmail,
            mobileNumber: hospitalMobileNumber,
            address: hospitalAddress,
          };
          dispatch(addHospital(payload));
        }
      } else {
        const payload = {
          userId: selectedOption,
          testIds: selectedOptionsTest,
          packageId: selectedOptionPackage,
          doctorId: selectedOptionDoctor,
          hospitalId: selectedOptionHospital,
          amount: amount,
          discountAmount: discount,
          dueAmount: dueAmount,
          onlineAmount: onlineAmount,
          paymentMode: paymentMode,
          bookingDate: moment(new Date()).format("YYYY-MM-DD"),
          bookingTime: moment(new Date()).format("HH:mm"),
        };
        console.log("Booking Payload ===> 3", payload);
        dispatch(postBookingRecord(payload));
      }
    } else {
      const payload = {
        userId: selectedOption,
        testIds: selectedOptionsTest,
        packageId: selectedOptionPackage,
        doctorId: selectedOptionDoctor,
        hospitalId: selectedOptionHospital,
        amount: amount,
        discountAmount: discount,
        dueAmount: dueAmount,
        cashAmount: cashAmount,
        onlineAmount: onlineAmount,
        paymentMode: paymentMode,
        bookingDate: moment(new Date()).format("YYYY-MM-DD"),
        bookingTime: moment(new Date()).format("HH:mm"),
      };
      console.log("Booking Payload ===> 4", payload);
      dispatch(postBookingRecord(payload));
    }
  };

  useEffect(() => {
    console.log("addPatientSuccess ===> ", addPatientSuccess);
    if (addPatientSuccess != null && addPatientSuccess.status === 1) {
      console.log("Patient success");
      setSelectedOption(addPatientSuccess.data._id);
      if (selectedOptionsTest.length == 0 && selectedOptionPackage == "") {
        alert("Please select any test or package!");
      } else if (reffered == 1) {
        const payload = {
          userId: addPatientSuccess.data._id,
          testIds: selectedOptionsTest,
          packageId: selectedOptionPackage,
          doctorId: selectedOptionDoctor,
          hospitalId: selectedOptionHospital,
          amount: amount,
          discountAmount: discount,
          paymentMode: paymentMode,
          dueAmount: dueAmount,
          cashAmount: cashAmount,
          onlineAmount: onlineAmount,
          bookingDate: moment(new Date()).format("YYYY-MM-DD"),
          bookingTime: moment(new Date()).format("HH:mm"),
        };
        console.log("Booking Payload ===> 5", payload);
        dispatch(postBookingRecord(payload));
      } else if (reffered == 2) {
        if (selectedOptionDoctor == "") {
          console.log("doctor selected out ===>", selectedOptionDoctor);
          if (doctorFirstName === "") {
            alert("Please Enter Doctor Name");
          } else if (doctorMobileNumber === "") {
            alert("Please Enter Doctor Mobile Number!");
          } else {
            const payload = {
              firstName: doctorFirstName,
              lastName: doctorLastName,
              email: doctortEmail,
              mobileNumber: doctorMobileNumber,
            };
            dispatch(addDoctor(payload));
          }
        } else {
          console.log("doctor selected in ===>", selectedOptionDoctor);
          const payload = {
            userId: addPatientSuccess.data._id,
            testIds: selectedOptionsTest,
            packageId: selectedOptionPackage,
            doctorId: selectedOptionDoctor,
            hospitalId: selectedOptionHospital,
            amount: amount,
            discountAmount: discount,
            paymentMode: paymentMode,
            dueAmount: dueAmount,
            cashAmount: cashAmount,
            onlineAmount: onlineAmount,
            bookingDate: moment(new Date()).format("YYYY-MM-DD"),
            bookingTime: moment(new Date()).format("HH:mm"),
          };
          console.log("Booking Payload ===> 6", payload);
          dispatch(postBookingRecord(payload));
        }
      } else if (reffered == 3) {
        if (selectedOptionHospital == "") {
          if (hospitalName === "") {
            alert("Please Enter Corporate Name");
          } else if (hospitalMobileNumber === "") {
            alert("Please Enter Corporate Mobile Number!");
          } else if (hospitalAddress === "") {
            alert("Please Enter Corporate Address!");
          } else {
            const payload = {
              name: hospitalName,
              email: hospitalEmail,
              mobileNumber: hospitalMobileNumber,
              address: hospitalAddress,
            };
            dispatch(addHospital(payload));
          }
        } else {
          const payload = {
            userId: addPatientSuccess.data._id,
            testIds: selectedOptionsTest,
            packageId: selectedOptionPackage,
            doctorId: selectedOptionDoctor,
            hospitalId: selectedOptionHospital,
            amount: amount,
            discountAmount: discount,
            paymentMode: paymentMode,
            dueAmount: dueAmount,
            cashAmount: cashAmount,
            onlineAmount: onlineAmount,
            bookingDate: moment(new Date()).format("YYYY-MM-DD"),
            bookingTime: moment(new Date()).format("HH:mm"),
          };
          console.log("Booking Payload ===> 7", payload);
          dispatch(postBookingRecord(payload));
        }
      } else {
        const payload = {
          userId: addPatientSuccess.data._id,
          testIds: selectedOptionsTest,
          packageId: selectedOptionPackage,
          doctorId: selectedOptionDoctor,
          hospitalId: selectedOptionHospital,
          amount: amount,
          discountAmount: discount,
          paymentMode: paymentMode,
          dueAmount: dueAmount,
          cashAmount: cashAmount,
          onlineAmount: onlineAmount,
          bookingDate: moment(new Date()).format("YYYY-MM-DD"),
          bookingTime: moment(new Date()).format("HH:mm"),
        };
        console.log("Booking Payload ===> 8", payload);
        dispatch(postBookingRecord(payload));
      }
    }
  }, [addPatientSuccess]);

  useEffect(() => {
    if (addDoctorSuccess != null && addDoctorSuccess.status === 1) {
      const payload = {
        userId: selectedOption,
        testIds: selectedOptionsTest,
        packageId: selectedOptionPackage,
        doctorId: addDoctorSuccess.data._id,
        hospitalId: selectedOptionHospital,
        amount: amount,
        discountAmount: discount,
        paymentMode: paymentMode,
        dueAmount: dueAmount,
        cashAmount: cashAmount,
        onlineAmount: onlineAmount,
        bookingDate: moment(new Date()).format("YYYY-MM-DD"),
        bookingTime: moment(new Date()).format("HH:mm"),
      };
      console.log("Booking Payload ===> 9", payload);
      dispatch(postBookingRecord(payload));
    }
  }, [addDoctorSuccess]);

  useEffect(() => {
    if (addHospitalSuccess != null && addHospitalSuccess.status === 1) {
      const payload = {
        userId: selectedOption,
        testIds: selectedOptionsTest,
        packageId: selectedOptionPackage,
        doctorId: selectedOptionDoctor,
        hospitalId: addHospitalSuccess.data._id,
        amount: amount,
        discountAmount: discount,
        paymentMode: paymentMode,
        dueAmount: dueAmount,
        cashAmount: cashAmount,
        onlineAmount: onlineAmount,
        bookingDate: moment(new Date()).format("YYYY-MM-DD"),
        bookingTime: moment(new Date()).format("HH:mm"),
      };
      console.log("Booking Payload ===> 10", payload);
      dispatch(postBookingRecord(payload));
    }
  }, [addHospitalSuccess]);

  const clearFields = () => {
    setPatientFirstName("");
    setPatientLastName("");
    setPatientAddress("");
    setPatientEmail("");
    setPatientMobile("");
    setPatientGender(0);
    setSelectedOptionPackage("");
    setSelectedOptionNamePackage("");
    setInputValueTest("");
    setSelectedOptionNameDoctor("");
    setSelectedOptionDoctor("");
    setSelectedOptionHospital("");
    setSelectedOptionNameHospital("");
    setDoctorFirstName("");
    setDoctorLastName("");
    setDoctorEmail("");
    setDoctorMobileNumber("");
    setDoctorAddress("");
    setHospitalName("");
    setHospitalEmail("");
    setHospitalMobileNumber("");
    setHospitalAddress("");
    setDueAmount(0);
    setDiscount(0);
    setAmount(0);
    setCashAmount(0)
    setOnlineAmount(0)
    setPayment(0);
    setReffered(1);
  };

  useEffect(() => {
    console.log("bookingRecordSuccess", bookingRecordSuccess);
    if (bookingRecordSuccess != null && bookingRecordSuccess.status == 1) {
      navigation("/booking");
      dispatch(clearAddDoctorData());
      dispatch(clearAddPatientData());
      dispatch(clearAddHospitalData());
      dispatch(clearPostBookingRecordData());
    }
  }, [bookingRecordSuccess]);


  const [nameCategory, setNameCategory] = useState("");
  const [amountCategory, setAmountCategory] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [from, setFrom] = useState(0)
  const [categories, setCategories] = useState([]);

  const [selectedOptionCategory, setSelectedOptionCategory] = useState('');
  const [selectedOptionNameCategory, setSelectedOptionNameCategory] = useState('');
  const [isDropdownOpenCategory, setIsDropdownOpenCategory] = useState(false);

  const getCategoryListSuccess = useSelector((state) => state.getCategoryListReducer.data);

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Selected option: ${selectedOption, selectedOptionName}`);
  };

  const handleInputChangeCategory = (event) => {
    setSelectedOptionNameCategory(event.target.value);
    setIsDropdownOpenCategory(true);
  };

  const handleSelectChangeCategory = (item) => {
    setSelectedOptionCategory(item._id);
    setSelectedOptionNameCategory(item.name);
    setIsDropdownOpenCategory(false);
  };

  const filteredOptionsCategory = getCategoryListSuccess?.data.filter(item =>
    item.name.toLowerCase().includes(selectedOptionName.toLowerCase())
  );

  const onSubmitClickCategory = () => {
    if (nameCategory.length == 0) {
      alert("Please enter name!");
    } else if (amountCategory.length == 0) {
      alert("Please enter amount");
    } else {
      if (from == 0) {
        const payload = {
          name: nameCategory,
          amount: amountCategory,
          categoryId: selectedOptionCategory,
        };
        setOpen(false);
        dispatch(addTest(payload));
      }
      else {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    if (getCategoryListSuccess != null && getCategoryListSuccess.status == 1) {
      setCategories(getCategoryListSuccess.data);
    }
  }, [getCategoryListSuccess]);

  useEffect(() => {
    if (addTestSuccess != null && addTestSuccess.status == 1) {
      const paylaod = {
        skip: -1,
      };
      dispatch(getTestList(paylaod));
      setNewTest(true)
    }
  }, [addTestSuccess])

  const onSelectPaymentMode = (value) => {
    setPaymentMode(value)
    if (value == 1) {
      setCashAmount(amount)
    } else if (value == 2) {
      setOnlineAmount(amount)
    }
  }


  return (
    <>
      <div className="dashboard">
        <div className="table_categories">
          <div className="categories_head">
            <div className="content_dropdown flex_column">
              <h2>Patient</h2>
              <div className="head_input_flex" style={{ marginLeft: 20 }}>
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <input
                    id="dropdown-input"
                    type="text"
                    placeholder="Search Patient"
                    value={selectedOptionName}
                    onChange={handleInputChange}
                    style={{ width: 300 }}
                    className="input_dropdown"
                    autoComplete="off"
                  />
                  <Button
                    type="button"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    style={{
                      marginLeft: "5px",
                      right: 12,
                      background: "transparent",
                      border: "1px solid #898686",
                      color: "#898686",
                      padding: 3,
                      borderRadius: 4,
                      position: "absolute",
                      top: 6,
                    }}
                  >
                    ▼
                  </Button>
                  {isDropdownOpen && (
                    <ul
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        listStyleType: "none",
                        margin: 0,
                        padding: "5px",
                        width: "100%",
                        maxHeight: "150px",
                        overflowY: "auto",
                      }}
                    >
                      {filteredOptions.length > 0 ? (
                        filteredOptions.map((item) => (
                          <li
                            key={item._id}
                            onClick={() => handleSelectChange(item)}
                            style={{ padding: "5px", cursor: "pointer" }}
                          >
                            {item.firstName +
                              " " +
                              item.lastName +
                              " " +
                              "(" +
                              item.mobileNumber +
                              ")"}
                          </li>
                        ))
                      ) : (
                        <li style={{ padding: "5px" }}>No results found</li>
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>

            <div className="submit_btn">
              <Button type="button" onClick={() => clearFields()}>
                Clear
              </Button>
            </div>
          </div>

          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="flex_input_box">
                <div className="head_input_flex">
                  <Form.Label>
                    First Name <span style={{ color: "#10519e" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    value={patientFirstName}
                    autoComplete="off"
                    onChange={(e) => setPatientFirstName(e.target.value)}
                    disabled={selectedOption != "" ? true : false}
                  />
                </div>
                <div className="head_input_flex">
                  <Form.Label>
                    Last Name <span style={{ color: "#10519e" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={patientLastName}
                    autoComplete="off"
                    onChange={(e) => setPatientLastName(e.target.value)}
                    disabled={selectedOption != "" ? true : false}
                  />
                </div>
              </div>
              <div className="flex_input_box" style={{ marginTop: 16 }}>
                <div className="head_input_flex">
                  <Form.Label>Email </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={patientEmail}
                    autoComplete="off"
                    onChange={(e) => setPatientEmail(e.target.value)}
                    disabled={selectedOption != "" ? true : false}
                  />
                </div>
                <div className="head_input_flex">
                  <Form.Label>
                    Mobile Number <span style={{ color: "#10519e" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Mobile Number"
                    value={patientMobile}
                    autoComplete="off"
                    onChange={(v) => {
                      if (
                        v.target.value.length <= 10 &&
                        /^\d*$/.test(v.target.value)
                      ) {
                        setPatientMobile(v.target.value);
                      }
                    }}
                    disabled={selectedOption != "" ? true : false}
                  />
                </div>
              </div>
              <div className="flex_input_box" style={{ marginTop: 16 }}>
                <div className="head_input_flex">
                  <Form.Label>
                    Address
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    value={patientAddress}
                    autoComplete="off"
                    onChange={(e) => setPatientAddress(e.target.value)}
                    disabled={selectedOption != "" ? true : false}
                  />
                </div>
                <div className="head_input_flex">
                  <Form.Label>
                    Gender <span style={{ color: "#10519e" }}>*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={patientGender}
                    onChange={(e) => setPatientGender(e.target.value)}
                    disabled={selectedOption != "" ? true : false}
                  >
                    <option>Select Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Other</option>
                  </Form.Select>
                </div>
              </div>
              <div className="flex_input_box" style={{ marginTop: 20 }}>
                <div className="head_input_flex_none">
                  <label htmlFor="dropdown-input">
                    Test <span style={{ color: "#10519e" }}>*</span>
                  </label>
                  <br />
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      whiteSpace: "nowrap",
                      width: "100%",
                      border: "1px solid #b7b6b6",
                      flexDirection: "row",
                      borderRadius: 4,
                      marginTop: 12,
                    }}
                  >
                    {/* <span className="inputValue" style={{ display: 'flex', alignItems: 'center', padding: selectedOptionsNamesTest == 0 ? 0 : "8px 10px 8px 0", marginLeft: selectedOptionsNamesTest == 0 ? 0 : 10 }}>
                      {selectedOptionsNamesTest}<img src={cross} alt="cross" style={{ width: '20px', height: '20px' }} />
                    </span> */}
                    <input
                      className="testInput"
                      id="dropdown-input"
                      type="text"
                      placeholder={
                        selectedOptionsNamesTest.length == 0
                          ? "Select Test"
                          : "Select Test"
                      }
                      value={selectedOptionsTestStr}
                      onChange={handleInputChangeTest}
                      // style={{ width: "auto" }}
                      autoComplete="off"
                    />
                    <Button
                      type="button"
                      onClick={() => setIsDropdownOpenTest(!isDropdownOpenTest)}
                      style={{
                        marginLeft: "5px",
                        position: "absolute",
                        right: 12,
                        background: "transparent",
                        border: "1px solid #898686",
                        color: "#898686",
                        padding: 3,
                        borderRadius: 4,
                        top: 6,
                      }}
                    >
                      ▼
                    </Button>
                    {isDropdownOpenTest && (
                      <ul
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          listStyleType: "none",
                          margin: 0,
                          padding: "5px",
                          width: "100%",
                          maxHeight: "212px",
                          overflowY: "auto",
                          top: 36,
                        }}
                      >
                        {filteredOptionsTest.length > 0 ? (
                          filteredOptionsTest.map((item) => (
                            <div>
                              <li
                                key={item._id}
                                onClick={() => handleSelectChangeTest(item)}
                                style={{
                                  padding: "5px",
                                  cursor: "pointer",
                                  marginBottom: 4,
                                  backgroundColor: selectedOptionsTestStr.includes(item.name)
                                    ? "#d4d4d4"
                                    : "transparent",
                                }}
                              >
                                {item.name}
                              </li>
                            </div>
                          ))
                        ) : (
                          <li style={{ padding: "5px" }}>No results found</li>
                        )}
                        {/* <div className='button_div'>
                          <Button onClick={() => setIsDropdownOpenTest(false)} className='button_ok'>OK</Button>
                        </div> */}
                      </ul>
                    )}
                  </div>

                  <Button onClick={() => setOpen(true)} style={{ marginLeft: 0, marginTop: 15 }}>Add Test</Button>
                </div>
              </div>
              <div className="flex_input_box" style={{ marginTop: 20 }}>
                <div className="head_input_flex_none">
                  <label htmlFor="dropdown-input">
                    Package <span style={{ color: "#10519e" }}>*</span>
                  </label>
                  <br />
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <input
                      id="dropdown-input"
                      type="text"
                      placeholder="Search Package"
                      value={selectedOptionNamePackage}
                      onChange={handleInputChangePackage}
                      style={{ width: "100%" }}
                      autoComplete="off"
                    />
                    <Button
                      type="button"
                      onClick={() =>
                        setIsDropdownOpenPackage(!isDropdownOpenPackage)
                      }
                      style={{
                        marginLeft: "5px",
                        right: 12,
                        background: "transparent",
                        border: "1px solid #898686",
                        color: "#898686",
                        padding: 3,
                        borderRadius: 4,
                        position: "absolute",
                        top: 20,
                      }}
                    >
                      ▼
                    </Button>
                    {isDropdownOpenPackage && (
                      <ul
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          listStyleType: "none",
                          margin: 0,
                          padding: "5px",
                          width: "100%",
                          maxHeight: "150px",
                          overflowY: "auto",
                        }}
                      >
                        {filteredOptionsPackage.length > 0 ? (
                          filteredOptionsPackage.map((item) => (
                            <li
                              key={item._id}
                              onClick={() => handleSelectChangePackage(item)}
                              style={{ padding: "5px", cursor: "pointer" }}
                            >
                              {item.name}
                            </li>
                          ))
                        ) : (
                          <li style={{ padding: "5px" }}>No results found</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="flex_input_box"
                style={{ marginTop: 30, marginBottom: 30 }}
              >
                <div className="head_input_flex" style={{ width: "49.5%" }}>
                  <Form.Label className="label_h2">
                    Reffered By <span style={{ color: "#10519e" }}>*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      if (selectedValue === "1") {
                        setReffered(1);
                      } else if (selectedValue === "2") {
                        setReffered(2);
                      } else if (selectedValue === "3") {
                        setReffered(3);
                      }
                    }}
                  >
                    <option value="1">Self</option>
                    <option value="2">Consultant</option>
                    <option value="3">Corporate</option>
                  </Form.Select>
                </div>
              </div>
            </Form.Group>

            {reffered == 2 && (
              <div className="hidden_show">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "49.5%",
                      }}
                    >
                      <input
                        id="dropdown-input"
                        type="text"
                        placeholder="Search Doctor"
                        value={selectedOptionNameDoctor}
                        onChange={handleInputChangeDoctor}
                        className="search_input_dropdown_doctor"
                        autoComplete="off"
                      />
                      <Button
                        type="button"
                        onClick={() =>
                          setIsDropdownOpenDoctor(!isDropdownOpenDoctor)
                        }
                        style={{
                          marginLeft: "5px",
                          right: 12,
                          background: "transparent",
                          border: "1px solid #898686",
                          color: "#898686",
                          padding: 3,
                          borderRadius: 4,
                          position: "absolute",
                          top: 6,
                        }}
                      >
                        ▼
                      </Button>
                      {isDropdownOpenDoctor && (
                        <ul
                          style={{
                            position: "absolute",
                            zIndex: 1,
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            listStyleType: "none",
                            margin: 0,
                            padding: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                          }}
                        >
                          {filteredOptionsDoctor.length > 0 ? (
                            filteredOptionsDoctor.map((item) => (
                              <li
                                key={item._id}
                                onClick={() => handleSelectChangeDoctor(item)}
                                style={{ padding: "5px", cursor: "pointer" }}
                              >
                                {item.firstName +
                                  " " +
                                  item.lastName +
                                  " " +
                                  "(" +
                                  item.mobileNumber +
                                  ")"}
                              </li>
                            ))
                          ) : (
                            <li style={{ padding: "5px" }}>No results found</li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="flex_input_box">
                    <div className="head_input_flex">
                      <Form.Label>
                        First Name <span style={{ color: "#10519e" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        value={doctorFirstName}
                        autoComplete="off"
                        onChange={(e) => setDoctorFirstName(e.target.value)}
                        disabled={selectedOptionDoctor != "" ? true : false}
                      />
                    </div>
                    <div className="head_input_flex">
                      <Form.Label>
                        Last Name <span style={{ color: "#10519e" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        value={doctorLastName}
                        autoComplete="off"
                        onChange={(e) => setDoctorLastName(e.target.value)}
                        disabled={selectedOptionDoctor != "" ? true : false}
                      />
                    </div>
                  </div>
                  <div className="flex_input_box" style={{ marginTop: 16 }}>
                    <div className="head_input_flex">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={doctortEmail}
                        autoComplete="off"
                        onChange={(e) => setDoctorEmail(e.target.value)}
                        disabled={selectedOptionDoctor != "" ? true : false}
                      />
                    </div>
                    <div className="head_input_flex">
                      <Form.Label>
                        Mobile Number{" "}
                        <span style={{ color: "#10519e" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Mobile Number"
                        value={doctorMobileNumber}
                        autoComplete="off"
                        onChange={(v) => {
                          if (
                            v.target.value.length <= 10 &&
                            /^\d*$/.test(v.target.value)
                          ) {
                            setDoctorMobileNumber(v.target.value);
                          }
                        }}
                        disabled={selectedOptionDoctor != "" ? true : false}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            )}

            {reffered == 3 && (
              <div className="">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "49.4%",
                      }}
                    >
                      <input
                        id="dropdown-input"
                        type="text"
                        placeholder="Search Corporate"
                        value={selectedOptionNameHospital}
                        onChange={handleInputChangeHospital}
                        className="search_input_dropdown_doctor"
                        autoComplete="off"
                      />
                      <Button
                        type="button"
                        onClick={() =>
                          setIsDropdownOpenHospital(!isDropdownOpenHospital)
                        }
                        style={{
                          marginLeft: "5px",
                          right: 12,
                          background: "transparent",
                          border: "1px solid #898686",
                          color: "#898686",
                          padding: 3,
                          borderRadius: 4,
                          position: "absolute",
                          top: 6,
                        }}
                      >
                        ▼
                      </Button>
                      {isDropdownOpenHospital && (
                        <ul
                          style={{
                            position: "absolute",
                            zIndex: 1,
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            listStyleType: "none",
                            margin: 0,
                            padding: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                          }}
                        >
                          {filteredOptionsHospital.length > 0 ? (
                            filteredOptionsHospital.map((item) => (
                              <li
                                key={item._id}
                                onClick={() => handleSelectChangeHospital(item)}
                                style={{ padding: "5px", cursor: "pointer" }}
                              >
                                {item.name +
                                  " " +
                                  "(" +
                                  item.mobileNumber +
                                  ")"}
                              </li>
                            ))
                          ) : (
                            <li style={{ padding: "5px" }}>No results found</li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="flex_input_box">
                    <div className="head_input_flex">
                      <Form.Label>
                        Name <span style={{ color: "#10519e" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        autoComplete="off"
                        value={hospitalName}
                        onChange={(e) => setHospitalName(e.target.value)}
                        disabled={selectedOptionHospital != "" ? true : false}
                      />
                    </div>
                    <div className="head_input_flex">
                      <Form.Label>Email </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        autoComplete="off"
                        value={hospitalEmail}
                        onChange={(e) => setHospitalEmail(e.target.value)}
                        disabled={selectedOptionHospital != "" ? true : false}
                      />
                    </div>
                  </div>
                  <div className="flex_input_box" style={{ marginTop: 16 }}>
                    <div className="head_input_flex">
                      <Form.Label>
                        Mobile Number{" "}
                        <span style={{ color: "#10519e" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Mobile Number"
                        value={hospitalMobileNumber}
                        onChange={(v) => {
                          if (
                            v.target.value.length <= 10 &&
                            /^\d*$/.test(v.target.value)
                          ) {
                            setHospitalMobileNumber(v.target.value);
                          }
                        }}
                        disabled={selectedOptionHospital != "" ? true : false}
                      />
                    </div>
                    <div className="head_input_flex">
                      <Form.Label>
                        Address
                      </Form.Label>
                      <Form.Control
                        type="type"
                        placeholder="Address"
                        autoComplete="off"
                        value={hospitalAddress}
                        onChange={(e) => setHospitalAddress(e.target.value)}
                        disabled={selectedOptionHospital != "" ? true : false}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            )}

            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
              style={{ marginTop: 20 }}
            >
              <div className="flex_input_box">
                <div className="head_input_flex">
                  <Form.Label>Amount </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    disabled
                    autoComplete="off"
                    value={amount}
                  />
                </div>
                <div className="head_input_flex">
                  <Form.Label>Discount </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Discount"
                    autoComplete="off"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex_input_box" style={{ marginTop: 16 }}>
                <div className="head_input_flex">
                  <Form.Label>
                    Due Amount
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Due Amount"
                    autoComplete="off"
                    style={{ color: "#000" }}
                    value={dueAmount}
                    onChange={(e) => setDueAmount(e.target.value)}
                  />
                </div>
                <div className="head_input_flex">
                  <Form.Label>Payment </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={payment}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      if (selectedValue === "1") {
                        onSelectPaymentMode(1);
                      } else if (selectedValue === "2") {
                        onSelectPaymentMode(2);
                      } else if (selectedValue === "3") {
                        onSelectPaymentMode(3);
                      }
                      setPayment(e.target.value);
                    }}
                  >
                    <option value="1">Cash</option>
                    <option value="2">Online</option>
                    <option value="3">Cash/Online</option>
                  </Form.Select>
                </div>
              </div>

              {paymentMode == 3 ? (
                <div className="flex_input_box" style={{ marginTop: 16 }}>
                  <div className="head_input_flex">
                    <Form.Label>Cash Amount </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Cash Amount"
                      autoComplete="off"
                      value={cashAmount}
                      onChange={(e) => setCashAmount(e.target.value)} />
                  </div>
                  <div className="head_input_flex">
                    <Form.Label>Online Amount </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Online Amount "
                      autoComplete="off"
                      value={onlineAmount}
                      onChange={(e) => setOnlineAmount(e.target.value)} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </Form.Group>

            <div className="submit_btn">
              <Button onClick={() => onSubmitClick()}>Submit</Button>
            </div>
          </Form>

          <Modal
            isOpen={isOpen}
            style={modal_setting}
            onRequestClose={() => setOpen(false)}
          >
            <div className='modal_content_center'>
              <div className="modal_header">
                <h2>Add Test</h2>
                <button onClick={() => setOpen(false)} className="close_button">
                  &times;
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                {from ? "" :
                  <div>
                    <label htmlFor="dropdown-input">Category <span style={{ color: '#10519e' }}>*</span></label><br />
                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                      <input
                        id="dropdown-input"
                        type="text"
                        placeholder='Select Category'
                        value={selectedOptionNameCategory}
                        onChange={handleInputChangeCategory}
                        style={{ width: '100%' }}
                      />
                      <Button
                        type="button"
                        onClick={() => setIsDropdownOpenCategory(!isDropdownOpenCategory)}
                        style={{
                          marginLeft: '5px',
                          position: 'absolute',
                          right: 15,
                          top: 20,
                        }}
                      >
                        ▼
                      </Button>
                      {isDropdownOpenCategory && (
                        <ul
                          style={{
                            position: 'absolute',
                            zIndex: 1,
                            backgroundColor: 'white',
                            border: '1px solid #ccc',
                            listStyleType: 'none',
                            margin: 0,
                            padding: '5px',
                            width: '100%',
                            maxHeight: '150px',
                            overflowY: 'auto',
                          }}
                        >
                          {filteredOptionsCategory.length > 0 ? (
                            filteredOptionsCategory.map((item) => (
                              <li
                                key={item._id}
                                onClick={() => handleSelectChangeCategory(item)}
                                style={{ padding: '5px', cursor: 'pointer' }}
                              >
                                {item.name}
                              </li>
                            ))
                          ) : (
                            <li style={{ padding: '5px' }}>No results found</li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                }
              </form>
              <div className='flex_input_box'>
                <div className='head_input_flex'>
                  <p style={{ marginTop: 16 }}>Name <span >*</span></p>
                  <input type='text' placeholder='name' autoComplete='off' value={nameCategory} onChange={(e) => setNameCategory(e.target.value)} /><br />
                </div>

                <div className='head_input_flex'>
                  <p style={{ marginTop: 16 }}>Amount <span>*</span></p>
                  <input type='number' placeholder='amount' autoComplete='off' value={amountCategory} onChange={(v) => setAmountCategory(v.target.value)} /><br />
                </div>
              </div>
              <div className='submit_btn'>
                <Button onClick={() => onSubmitClickCategory()}> Submit</Button>
              </div>
            </div>
          </Modal>

        </div>
      </div>
    </>
  );
};

export default NewBooking;
