import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import MedicationIcon from '@mui/icons-material/Medication';
import { clearLoginData } from "../../redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearlogoutData, logout } from "../../redux/logoutSlice";
import GridViewIcon from '@mui/icons-material/GridView';
import CategoryIcon from '@mui/icons-material/Category';
import LogoutIcon from '@mui/icons-material/Logout';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import test_img from '../../assests/img/test_img.png'
import patient_icon from '../../assests/img/patient_icon.png'
import doctor_icon from '../../assests/img/doctor_icon.png'
import hospital_icon from '../../assests/img/hospital_icon.png'
import booking_icon from '../../assests/img/booking_icon.png'
import commisson_icon from '../../assests/img/commisson_icon.png'
import feedback_icon from '../../assests/img/feedback_icon.png'
import contact_us from '../../assests/img/contact_us.png'

const Sidebar = () => {

  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const logoutSuccess = useSelector((state) => state.logoutReducer.data);

  useEffect(() => {
    if (logoutSuccess != null && logoutSuccess.status == 1) {
      dispatch(clearLoginData());
      dispatch(clearlogoutData())
      navigation('/')
    }
  }, [logoutSuccess]);


  const onLogoutClick = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
        <div className="top-section">
          <h1 className="logo">
            <MedicationIcon />{" "}
            Kior
          </h1>
          {/* <div className="bars" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div> */}
        </div>
        <nav className="nav-menu">
          <ul>
            {/* <li>
              <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')} state={{ isOpen: isOpen }}  >
                <GridViewIcon className={({ isActive }) => (isActive ? 'active-link' : 'span_color')} />{" "}
                <span>Dashboard</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/categories" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                <CategoryIcon className={({ isActive }) => (isActive ? 'active-link' : 'span_color')} />{" "}
                <span>Categories</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/test" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                {({ isActive }) => (
                  <>
                    <img src={test_img} alt="test_img" className={isActive ? 'active-link-img' : 'span_color-img'} />{" "}
                    <span>Tests</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink to="/packages" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                <InventoryOutlinedIcon className={({ isActive }) => (isActive ? 'active-link' : 'span_color')} />{" "}
                <span>Packages</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/patient" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                {({ isActive }) => (
                  <>
                    <img src={patient_icon} alt="patient_icon" className={isActive ? 'active-link-img' : 'span_color-img'} />{" "}
                    <span>Patient</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink to="/doctor" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                {({ isActive }) => (
                  <>
                    <img src={doctor_icon} alt="doctor_icon" className={isActive ? 'active-link-img' : 'span_color-img'} />{" "}
                    <span>Doctor</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink to="/hospital" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                {({ isActive }) => (
                  <>
                    <img src={hospital_icon} alt="hospital_icon" className={isActive ? 'active-link-img' : 'span_color-img'} />{" "}
                    <span>Hospital</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink to="/booking" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')} state={{ isOpen: isOpen }} >
                {({ isActive }) => (
                  <>
                    <img src={booking_icon} alt="booking_icon" className={isActive ? 'active-link-img' : 'span_color-img'} />{" "}
                    <span>Booking</span>
                  </>
                )}

              </NavLink>
            </li>
            <li>
              <NavLink to="/commission" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                {({ isActive }) => (
                  <>
                    <img src={commisson_icon} alt="commisson_icon" className={isActive ? 'active-link-img' : 'span_color-img'} />{" "}
                    <span>Commission</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink to="/feedback" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                {({ isActive }) => (
                  <>
                    <img src={feedback_icon} alt="feedback_icon" className={isActive ? 'active-link-img' : 'span_color-img'} />{" "}
                    <span>Feedback</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact-us" className={({ isActive }) => (isActive ? 'active-link' : 'span_color')}>
                {({ isActive }) => (
                  <>
                    <img src={contact_us} alt="contact_us" className={isActive ? 'active-link-img' : 'span_color-img'} />{" "}
                    <span>Contact Us</span>
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => onLogoutClick()} >
                <LogoutIcon style={{ color: '#fff' }} />{" "}
                <span style={{ color: '#fff' }}>Logout</span>
              </NavLink>
            </li>
          </ul>

        </nav>
      </div>
    </>
  );
};

export default Sidebar;

